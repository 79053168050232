
.notificacao-conta{
    background-color: $red-light;
    color: $white;
        h4{
            color: $white;
        }
        svg{
            width: 3em;
            height: 3em;
        }
}

.dados-conta{
    h6{
        font-size: .85em;
    }
    p{
        color: $blue;
    }
}

.view-dados{
    overflow: auto;
    height: calc(100vh - 7.1rem);
}