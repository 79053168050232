

.footerNewLead{
    @include border-bottom;
}

.check_novo_lead{
    justify-content: space-between; align-items: center; padding-top: 1em; padding-bottom: 1em;
}

.simplebar-content{
    padding-top: 0;
}