.react-flow{
    background-color: white;
    border: 1px solid #f2f2f2!important;
}

.react-.react-flow__controls-button{
    background: transparent;
}

#area-bot {
    select {
        color: #777;
    }
    select, textarea, input{
        padding: .5em;
        width: 100%;
        background-color: white;
        border: 1px solid #fff;
        outline: none;
        box-shadow: none;
        border-radius: 5px;

        &:focus{
            outline: none;
            box-shadow: none;
            border: 1px solid #dedede;
        }
    }
}

#area-bot span:not(.ReactTags__tag) {
    color: red !important;
}

.react-flow__handle { 
    width: 10px !important;
    height: 10px !important;
}

.node-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: .5em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.custom-field-node {
    max-width: 30rem;
    border-radius: 5px;
    background-color: #f2f2f2;
    border: 1px solid #e0e0e0;

        svg{
            width: 1em;
        }

        .node-type {
            .btn-primary{
                color: #000;
            }
        }

        .delete-node {
            padding:0;

            &:hover{
                color: red;
            }


            .icon {
                width: 80%;
            }
    }

    .title{
        padding: .5em;
        
        input { 
        display: block;
        color: rgb(172, 172, 172);
        font-size: 12px;
        border: 1px solid #fff;
        outline: none;
        box-shadow: none;
        background-color: white;
        padding: .5em;
        width: 100%;
        border-radius: 5px;

            &:focus{
                outline: none;
                box-shadow: none;
                border: 1px solid #dedede;
            }
        }
    }

    label {
        display: block;
        color: #777;
        font-size: 14px;
    }

    .options {
        border-top: 1px solid #f2f2f2;
        padding: .5em;

        select {
            color: #777;
        }
        select, textarea, input{
            padding: .5em;
            width: 100%;
            background-color: white;
            border: 1px solid #fff;
            outline: none;
            box-shadow: none;
            border-radius: 5px;

            &:focus{
                outline: none;
                box-shadow: none;
                border: 1px solid #dedede;
            }
        }

        .radio-options {
            display: flex;
            align-items: center;

            .radio-option {
                display: flex;
                align-items: center;
            }

            .radio-option:not(:first-child) {
                margin-left: 20%;
            }

            .radio-option input[type="radio"] {
                margin-right: 0.25rem;
            }
        }

    }

    div:not(:first-child) {
        
    }

    .ReactTags__tagInput {
        margin-top: 0.2rem;
    }

    .ReactTags__selected {
        .ReactTags__remove {
            border: none;
            background-color: transparent;
            color: #777;
            display: inline-block;
            margin-left: 0.3rem;
        }
    }

}
.node-header {
    padding: .5em;
    justify-content: space-between;
    align-items: center;

    div{
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg{
            margin-right: .5em;
        }

        p{
            margin-bottom: 0;
        }
    }
}

.finish-node {
    max-width: 30rem;
    border-radius: 5px;
    background-color: #f2f2f2;
    border: 1px solid #e0e0e0;

    .title{
        padding: .5em;
        
        input { 
        display: block;
        color: rgb(172, 172, 172);
        font-size: 12px;
        border: 1px solid #fff;
        outline: none;
        box-shadow: none;
        background-color: white;
        padding: .5em;
        width: 100%;
        border-radius: 5px;

            &:focus{
                outline: none;
                box-shadow: none;
                border: 1px solid #dedede;
            }
        }
    }

    .node-header {
        padding: .5em;
        justify-content: space-between;
        align-items: center;

        }

        .delete-node {
            padding: 0;
            &:hover{
                color: red;
            }
    }

    .options {
        border-top: 1px solid #f2f2f2;
        padding: .5em;

        select {
            color: #777;
        }
        select, textarea, input{
            padding: .5em;
            width: 100%;
            background-color: white;
            border: 1px solid #fff;
            outline: none;
            box-shadow: none;
            border-radius: 5px;

            &:focus{
                outline: none;
                box-shadow: none;
                border: 1px solid #dedede;
            }

        }

        .radio-options {
            display: flex;
            align-items: center;

            .radio-option {
                display: flex;
                align-items: center;
            }

            .radio-option:not(:first-child) {
                margin-left: 20%;
            }

            .radio-option input[type="radio"] {
                margin-right: 0.25rem;
            }
        }

        div:not(:first-child) {
            
        }

        .ReactTags__tagInput {
            margin-top: 0.2rem;
        }

        .ReactTags__selected {
            .ReactTags__remove {
                border: none;
                background-color: transparent;
                color: white;
                display: inline-block;
                margin-left: 0.3rem;
            }
        }
    }
}


button.btnOptions{
    color: white;
    background-color: #000;
    margin: .5em;
    border: none;
    border-radius: 5px;
    width: 100%;
    font-size: 12px;
    margin: .5em;
}

.draggable-options {
    cursor: grab;
    padding: .5em 1em;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;

    svg{
        width: 1em;
        margin-right: .5em;
        color: white;
    }
}

#opcoes-bot{

    ul{
        li{
            font-size: .8em;

            svg{
                width: 1.5em;
            }

            .btn-error{
                background-color: #ff1e1e;
            }

            .btn-transfer{
                background-color: #3498db;
            }

            .btn-options {
                background-color: #ffb91e;
            }

            button{
                font-size: 1.1em;
                svg{
                    width: 1.4em;
                }
            }
        }
    }
}

.btn-remove-tiny {
    margin-left: 1em !important;
    width: initial !important;
    border: 0px !important;
}

.react-flow__attribution{
    display: none;
}

.btn{

    &:focus{
        border: 0;
        outline: 0;
        box-shadow: 0;
    }
}

.message-container {
    .textarea-box {
        width: 25em !important;

        textarea {
            width: 25em !important;
            resize: vertical;
            box-sizing: border-box;
        }
    }

    .remove-message {
        color: #7a7f9a !important;
    }

    .menu-btns {
        margin-left: 2em !important;

        .file-uploader {
            .custom-file-upload {
                border: 1px solid #7a7f9a;
                display: inline-block;
                padding: 6px 12px;
                cursor: pointer;
                background-color: #7a7f9a;
                border-radius: 0.25rem;
                color: #f5f7fb !important;
                transition: color 0.15s ease-in-out,
                    background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
            }

            .custom-file-upload:hover {
                background-color: #686c83;
                border-color: #62667b;
            }

            input[type="file"] {
                display: none;
            }
        }
    }
}
.modals-dafault-bot {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .modal-content {
        background-color: white;

        .modal-header {
            padding: 10px;

            .btn-transfer {
                background-color: #3498db;
            }
        }

        .modal-body {
            padding: 10px;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;
        }

        .modal-footer {
            padding: 10px;

            .btn-success {
                background-color: #00AEED !important;
            }

            .btn-cancel {
                color: #495057 !important;
                background-color: transparent !important;
            }
        }
    }
}