// 
//  User profile details.scss
//

// User profile details

.user-profile-sidebar-tasks {
    height: 100vh;
    background-color: $sidebar-sub-bg;
    display: none;
    min-width: 380px;
    max-width: 380px;
    transition: transform 1s ease-in-out;

    .nav-btn{
        transform: rotate(90deg);
    }

    @media (min-width: 992px) {
        border-left: 1px solid $border-color;
    }

    @media (max-width: 1199.98px) {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 99;
    }

    @media (max-width: 575.98px) {
        min-width: 100%;
    }
}

.user-profile-desc {
    // height: calc(100vh - 140px);

    @media (max-width: 991.98px) {
        height: calc(100vh - 150px);
    }

}

.dados-oport{
    height: calc(100vh - 10em);
}

.avatar-sidebar{
    position: relative;
    z-index: 1;
    margin: 0 auto;
    width: 6em;

.origem{position: absolute; right: 5px; bottom: 5px; z-index: 3; width: 2em;

    }

    
}

.add_oportunidade .form-group{display: flex; align-items: center;
    
    i{
        width: 2em; height: 2em; margin-right: .5em; display: flex;
    }
}
.tabela{
    border:1px solid #F4F5F7;
    background-color: #FFFFFF;


    thead{
        background: #F4F5F7;

        th{
            border-bottom: none;
        }
    }

    td:last-child{
        flex-direction: flex-end;
        width: 2em;
    }

    button{
        padding: 0;
    }
}