// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}

button:focus,a:focus, .btn-check:focus + .btn, .btn:focus {
  box-shadow: none; border: none;
  color: 800;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
  border-radius: 30px;
}

// Rounded Plus Buttons
.btn-x {
  border-radius: 100px;
  border-radius: 100px;
  padding: .5em 1.2em;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

.btn-default{
  background-color: $blue;
  color: $white;
}

.btn-bottom{
  display: flex;
  align-items: flex-end;
}

.btn-teal{
  background-color: $teal; color: $white; border: none;
    &:hover{
      background-color: $gray-800; color: $white;
    }
}

.show > .btn-secondary.dropdown-toggle {

  background-color: $white;
  border: none;
}