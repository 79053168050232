
.card-header{
    padding: 0.75rem 0.75rem;
}
.card-lead{

    .card-title{
        img{
            margin-bottom: .5em;
        }
    }
    p{
        margin-bottom: 0;
    }

    .card-body{
        ul{
            text-align: center;
        }
    }
    label{
        font-size: .8em;
        color: $gray-600;
        margin-bottom: 0;
        line-height: 1em;
    }
.responsavel{

    img{
        margin-right: .5em;
    }
    p{
        line-height: .8em;
    }
}

}
.btn-group{
    button{
        padding: 0.27rem 0.55rem;
        border-radius: 4px;
    }
}

.btn-disabled{
    background-color: $white;
    border: $white;
    color: $gray-500;
}

