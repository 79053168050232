// 
// _nav.scss
// 
.nav-tabs,
.nav-pills {
    >li {
        >a {
            color: $gray-700;
            font-weight: $font-weight-medium;
            font-family: $font-family-secondary;
        }
    }
}

.nav-pills {
    >a {
        color: $gray-700;
        font-weight: $font-weight-medium;
    }
}

.nav-tabs-custom {
    border-bottom: none;
    font-family: $font-family-secondary;
    .nav-item {
        position: relative;
        color: $dark;
        .nav-link {
            border: none;
            color: $gray-700;
            &::after {
                content: "";
                background: $blue;
                height: 4px;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: -1px;
                transition: all 250ms ease 0s;
                transform: scale(0);
            }
            &.active {
                color: $primary;
                &:after {
                    transform: scale(1);
                }
            }
            &:hover{
                color:$blue;
            }
        }
    }
}

// vertical nav
.vertical-nav {
    .nav {
        .nav-link {
            padding: 24px 16px;
            text-align: center;
            font-family: $font-family-secondary;
            color: $gray-700;
            .nav-icon {
                font-size: 24px;
            }
        }
    }
}

// vertical nav
.vertical-menu {
    border-right: 1px solid $gray-400;
    padding-right: 0;
    flex-wrap: wrap;
    background-color:$gray-400;
    .nav-item {
        .nav-link {
            padding: 1em;
            font-family: $font-family-secondary;
            color: $gray-700;

            &:hover{
                background:#eceff7;
            }
            &.active {
                color: $primary;
                background-color: $white;
                border-right: 5px solid $blue;
            }
        }
    }
}