//
// user chat.scss
//

em {
  word-break: break-all;
}
.ctext-wrap-content {
  a {
    word-break: break-all;
  }
}

// user chat
.conectar-celular {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100%;
  background: rgb(0, 171, 233);
  background: linear-gradient(
    -45deg,
    rgba(0, 171, 233, 1) 0%,
    rgba(0, 174, 237, 1) 100%
  );

  img {
    margin-bottom: 1em;
  }
}

.bate-papo-100 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100% !important;
}
.bate-papo-70 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 73% !important;
}

.header-conversa,
.footer-conversa,
.abas {
  background-color: $white;
  &.note {
    background-color: #fff5cc;
  }
}

.footer-conversa {
  min-height: auto;
  padding: 0.8em;
  border-top: 1px solid #f2f2f2;
}

.abas {
  a {
    padding: 0.5em 1em;
    &.active {
      color: #000;
    }
  }
}

.box-msg-padrao{
  background-color: $gray-100;
  padding: 1em;

  .list-msg-padrao{
    li{
      background: white;
      border: 1px solid #dedede;
      margin-bottom: 0.3em;
      display: flex;
      align-items: center;
      color: $gray-500;

      .tit-msg{
        font-weight: bold;
        margin-right: .3em;
        color: $gray-700;
      }
      p{
        width: 100%;
        padding: 0.3em;
        
        &:hover {
          cursor: pointer;
          display: block;
          color: rgb(0, 174, 237);
        }
        span{
          &:hover {
            cursor: pointer;
            color: rgb(0, 174, 237);
          }
        }
      }

      .icon-anexo{
        svg{
          width: 1em;
          margin:0 .5em;
          color: $gray-500;
        }
      }
    }
  }

  .search-input {
    height: 2.37rem;
  }
}

.arq-anexos{
  padding-left: 0!important;
  label{
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    padding: .5em;
    width: fit-content;
    margin-bottom: 1em;
    cursor: pointer;
  }
}
.form-check .form-check-input{
  margin-left: 0!important;
}


.user-chat {
  background-image: url('./bg-whats.png');
  background-repeat: repeat;
  transition: all 0.5s ease-in-out;
  width: 100%;
  height: 100vh;

  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 99;

    &.user-chat-show {
      visibility: visible;
      transform: translateX(0);
    }
  }
}

.user-chat-nav {
  .nav-btn {
    height: 40px;
    width: 40px;
    line-height: 40px;
    box-shadow: none;
    padding: 0;
    font-size: 20px;
    color: $gray-600;
  }
  @media (max-width: 575.98px) {
    display: flex;
    justify-content: flex-end;
  }
}

.isMobile {
  padding-top: 5px;
}

.chat-conversation {
  li {
    clear: both;
  }

  .chat-avatar {
    margin: 0 16px 0 0 /*rtl:0 0 0 16px*/;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 12px;

    .title {
      background-color: $border-color;
      position: relative;
      font-size: 13px;
      z-index: 1;
      padding: 6px 12px;
      border-radius: 5px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }

    .badge {
      font-size: 12px;
    }
  }

  .conversation-list {
    margin-bottom: 24px;
    display: inline-flex;
    position: relative;
    align-items: flex-end;
    max-width: 75%;

    .ctext-wrap {
      display: flex;
      margin-bottom: 10px;
    }

    .ctext-wrap-content {
      padding: 12px 20px;
      background-color: $chat-secondary-bg;
      border-radius: 8px 8px 8px 0;
      color: $dark;
      position: relative;
      box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.06);

      &:before {
        content: '';
        position: absolute;
        border: 5px solid transparent;
        border-left-color: $chat-secondary-bg;
        border-top-color: $chat-secondary-bg;
        left: 0 /*rtl:auto*/;
        right: auto /*rtl:0*/;
        bottom: -10px;
      }
    }

    .conversation-name {
      font-weight: $font-weight-medium;
      font-size: 14px;
    }

    .dropdown {
      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: $gray-600;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }

    .chat-time {
      font-size: 12px;
      margin-top: 4px;
      text-align: right;
      color: rgba($white, 0.5);
    }

    .message-img {
      border-radius: 0.2rem;
      position: relative;

      .message-img-list {
        position: relative;
      }

      img {
        max-width: 350px;
      }

      .message-img-link {
        position: absolute;
        right: 10px /*rtl:auto*/;
        left: auto /*rtl:0*/;
        bottom: 10px;

        li {
          > a {
            font-size: 18px;
            color: $white;
            display: inline-block;
            line-height: 30px;
            width: 30px;
            height: 30px;
            text-align: center;
          }
        }
      }
    }
  }

  .right {
    .chat-avatar {
      order: 3;
      margin-right: 0px;
      margin-left: 16px;
    }

    .chat-time {
      text-align: left;
      color: $gray-600;
    }

    .conversation-list {
      float: right;
      text-align: right;

      .ctext-wrap {
        justify-content: flex-end;

        .ctext-wrap-content {
          word-wrap: break-word;
          order: 2;
          background-color: $white;
          color: $dark;
          text-align: left;
          border-radius: 8px 8px 0px 8px;
          &.note {
            background-color: #fff5cc;
          }
          &:before {
            border: 5px solid transparent;
            border-top-color: $white;
            border-right-color: $white;
            left: auto;
            right: 0px;
            word-wrap: break-word;

            &.note {
              border-top-color: #fff5cc;
              border-right-color: #fff5cc;
            }
          }
        }
      }

      .dropdown {
        order: 1;
      }
    }


    .dot {
      background-color: $dark;
    }
  }
}
.chat-conversation {
  // height: calc(100vh - 151px);
  @media (max-width: 991.98px) {
    height: calc(100vh - 143px);
  }
}

.chat-input-links {
  .list-inline-item:not(:last-child) {
    margin: 0;
  }

  .dropdown-menu-end[style] {
    right: 0 !important;
    left: none !important;
    top: -1em;
  }
}

.list-inline-item{
  display: flex;
  align-items: center;
  .btn{
    padding: 0.30em .50em;
  }
}

.animate-typing {
  .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: -1px;
    background: $white;
    animation: wave 1.3s linear infinite;
    opacity: 0.6;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-5px);
  }
}

.anexo,
.icon_chat {
  width: auto;
  padding: 0;
}
.caixa_msg {
  padding: 0 0 0 1em;

  .form-control{
    max-height: 11em;
    overflow: auto;
    word-wrap: break-all;
  }
}
.chat-input-links{
  .list-inline{
    display: flex;
  }
}

.dropdown_trocar {
  padding: 1em;
  width: 17em;
}

.list-inline-item {
  position: relative;
}

#msgPronta {
  padding: 0;
  width: 355px;

  header {
    @include border-bottom;
    padding: 1em;

    h6 {
      margin-bottom: 0;
    }
  }

  .simplebar-wrapper {
    height: 200px;
  }
  ul {
    padding-left: 0;
    margin: 0.5em;
    // overflow: auto;
    // height:8em;
  }
  li {
    padding: 0.5em;
    list-style: none;
    @include border-bottom;

    &:hover {
      background: #f2f2f2;
    }
  }

  .buscarMsg {
    padding: 0.5em;
    display: flex;
    background: #f4f5f7;

    input {
      z-index: 1;
      position: relative;
    }

    button {
      z-index: 3;
      position: absolute;
      right: 0.3em;
    }
  }
}
