


.blue{color: $blue;}
.indigo{color: $indigo;}
.pink{color: $pink;}
.indigo{color: $indigo;}
.purple{color: $purple;}
.red{color: $red;}
.orange{color: $orange;}
.yellow{color: $yellow;}
.green{color: $green;}
.teal{color: $teal;}
.cyan{color: $cyan;}
.gray-600{color: $gray-600;}

.whatsapp, .messenger, .telegram, .instagram, .email, .telefone, .site, .arq-anexo, .tarefa, .anotacao{
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: inline-block;
    svg{
        padding: .3em;
        color: $white!important;
    }
}

.whatsapp{background: $whatsapp;}
.messenger{background: $messenger;}
.telegram{background: $telegram;}
.instagram{background: $instagram;}
.site{background: $site;}
.email{background: $purple;}
.telefone{background: $black;}
.arq-anexo{background: $orange;}
.anotacao{background: $indigo;}
.tarefa{background: $pink;}

.btn-indigo{background: $indigo;}
.btn-pink{background: $pink;}
