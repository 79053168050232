
.header-body{
    h2{
        color: $black;
        a{
            &:hover{
            color: $blueHover;
            }
        }
    }
}
.cargo{

    color: $gray-700;
    font-weight: 300;
    font-size: .9em;
}

.dia{
    display: flex;
    align-items: center;
    padding: .7em 0 .7em .7em;
    border-bottom: 1px solid #f2f2f2;
}

.membro{
    width: 31.5%;
    padding: 1.5em; 
    margin: .5em;
    border: 1px solid $gray-400;;
    border-radius: 7px;
    display: inline-flex;
    align-items: center;

    img{
        margin-right: 1em;
    }

    h6{
        margin-bottom: 0;
    }
    &:hover{
        background-color: $gray-300;
        box-shadow: $box-shadow;
    }
    &.active{
        background-color: $white;
        box-shadow: $box-shadow;
    }
}

.produto{
    border-radius: 50px;
    padding: .3em .6em;
    color: $white;
    background-color: $blue;
}
.servico{
    border-radius: 50px;
    padding: .3em .6em;
    color: $white;
    background-color: $indigo;
}
.ativo{
    border-radius: 50px;
    padding: .3em .6em;
    color: $white;
    background-color: $green;
}
.inativo{
    border-radius: 50px;
    padding: .3em .6em;
    color: $white;
    background-color: $red;
}

// TAGS
.tag-box{
    
    svg{
    width: 4.5em;
    height: 4em;
    }
}

// ORIGEM

.origem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;

    .detalhes-origem{
        display: flex;
        align-items: center;
        gap: 1em;
        padding: 1em 0;
        div{
            display: flex;
        align-items: center;

            h6{
                margin: 0;
            }
        }

    }
    .acoes-origem{
        align-items: center;
    }
}

.campanhas{
    padding: 1em;
    .item-campanha{
        display: flex;
        justify-content: space-between;
        background-color: white;
        padding: 4px 8px;
        margin: 4px 0px;
        border-radius: 6px;
    }
    .detalhes-campanha {
        display: grid;
        grid-template-columns: 50px 1fr 98px 98px 100px;
        width: 90%;
    }

    .item-campanha {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $gray-200;
        padding: 6px 6px;
        margin: 4px 0px;
        border-radius: 6px;

        &:hover{
            background-color: $gray-400;
        }
    }
    .item-campanha.header{
        background-color: $gray-300;
    }

}

.box-card{

    .item-campanha{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $gray-200;
        padding: 6px 6px;
        margin: 4px 0px;
        border-radius: 6px;
    }
    .item-campanha.header{
        flex-direction: row;
        justify-content: space-between;
        background-color: $gray-300;
    }

    .detalhes-campanha{

        width: 100%;

        .desc{
            display: flex;
            justify-content: center;
            padding: .8em 0;
        }

    }
}

.data{
    background-color: $gray-200;
    justify-content: center;
    li{
        &:first-child{
        border-right:2px solid $gray-400;
        padding-right: .7em;
        margin-right: .7em;
        }
    }
}

.detalhes-campanha, .acoes-origem, .acoes-campanha {
    gap:1em;
}

.icon-blue{
    color: $blue;
    margin-right: .5em;
}

.search{
    .form-control{
        border-radius: 4px 0 0 4px;
        border-right: 0;
        
    }
    .btn{
        background-color: $white;
        color: $blue;
        border-top: 1px solid #e6ebf5;
        border-right: 1px solid #e6ebf5;
        border-bottom: 1px solid #e6ebf5;
        border-left: 0;
        border-radius: 0 4px 4px 0;
    }
}


.header-cx-email{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    button{
        display: flex;
        align-items: center;
    }
}

.corpo-email{
    .card-body{
        border-bottom: 1px dashed $gray-300;
    }
}
.list-email{
    border-radius: 0 0 0.25em 0.25em;
    li{
        border-bottom: 1px solid $gray-300;
        &:hover{
            box-shadow: 0 0 8px rgba($color: #000000, $alpha: 0.07);
        }
        a{
            display: grid;
            align-items: center;
            padding: 1em;
            grid-template-columns: 2fr 5fr auto;
            color: $black;

            &:hover{
                color: $blue;
            }
        p{
            margin-bottom: 0;
            padding: 0 1em;
        }
        .font-email, .desc-email{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .data-email{
            display: flex;
            justify-content: flex-end;
        }
    }
}
}
.btn-escrever{
    height: 100%;
    svg{
        width: 1.2em;
        height: auto;
    }
}
#nav-email{
    margin-bottom: 0;
    border-bottom: 1px solid $gray-500;
    border-radius: 0.25em 0.25em 0 0;
    svg{
        width: 1.5em;
    }
}

.modal_encerrar{
    .rdw-editor-toolbar{
        margin-bottom: 0;
    }
}

// CONFIG EMAIL

.card-usuarios{
    flex-wrap: wrap;
    label{
        flex-grow: 1;
        width: 30%;
        margin:0 0 2% 0;

        &:nth-child(3n+2){
            margin-left: 2%;
            margin-right: 2%;
        }

        &:hover{
            cursor: pointer;
        }
    }
    h6{
        margin:0 0 0 1em;
    }
}