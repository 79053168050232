//
// _badge.scss
//

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $white;
  background-color: rgba($bg, 1);

  &[href] {
    color: $bg;
    text-decoration: none;
    background-color: rgba($bg, 0.4);
  }

  // &[href] {
  //   @include hover-focus {
  //     color: $bg;
  //     text-decoration: none;
  //     background-color: rgba($bg, 0.4);
  //   }
  // }
}

@each $color, $value in $theme-colors {
  .badge-soft-#{$color} {
    @include badge-variant-soft($value);
  }
}

// Dark badge

.badge-dark {
  color: $light;
}

.tags {
  .badge {
    margin-right: 0.3em;
  }
}

.badge {
  width: fit-content;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-success {
  background-color: $green;
}
.badge-pill {
  border-radius: 2em;
}
