
.card-stats{
    .h2{
        padding: 0 0.3em;
    }
}

.at-abertos{
    border-top: 4px solid $blue;
    svg{
        color: $blue;
    }
}

.at-espera{
    border-top: 4px solid $orange;
    svg{
        color: $orange;
    }
}
.at-finalizados{
    border-top: 4px solid $green;
    svg{
        color: $green;
    }
}
.at-bot{
    border-top: 4px solid $indigo;
    svg{
        color: $indigo;
    }
}