h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

h5 {
  font-weight: 600;
}

ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}

td,
th {
  align-items: center;
  vertical-align: middle;
}

input {
  &[type="time"] {
    cursor: pointer;
  }
}

.form-check-input {
  margin: 0 0.5em 0 0;
}

.btn-atalho {
  position: relative;

  .menu-atalho {
    position: absolute;
    top: 2.8em;
    left: 0;
    visibility: hidden;
    a {
      padding: 0.5em 1em;
      display: block;
      border-bottom: 1px solid $gray-200;
    }
  }
  &:hover {
    .menu-atalho {
      visibility: visible;
    }
  }
}

.pesquisa-geral {
  position: relative;
  width: 20em;
  button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    background: transparent;
    border: 0;
  }
  .form-control {
    position: absolute;
    left: 0;
    border: 0;
  }
  input {
    &:focus {
      background-color: $gray-100;
      border-radius: 8em;
    }
  }
}

.bg-cinza {
  border-radius: 5px;
  background-color: #f5f7fb;
  padding: 1em;
}

.modal-content {
  background-color: $bg-cinza;
}

.mr-1 {
  margin-right: 0.5em;
}
.mr-2 {
  margin-right: 1em;
}
.mr-3 {
  margin-right: 1.5em;
}
.ml-1 {
  margin-left: 0.5em;
}
.ml-2 {
  margin-left: 1em;
}
.ml-3 {
  margin-left: 1.5em;
}

.pr-1 {
  padding-right: 0.5em;
}
.pr-2 {
  padding-right: 1em;
}
.pr-3 {
  padding-right: 1.5em;
}
.pl-1 {
  padding-left: 0.5em;
}
.pl-2 {
  padding-left: 1em;
}
.pl-3 {
  padding-left: 1.5em;
}

.text-sm {
  color: $gray-700;
  font-size: 0.8em;
}

.icon-sm {
  width: 1.2em;
}

.table > :not(:first-child) {
  border-top: 1px solid currentColor;
}

.text-active {
  color: $primary;
}

.input-custom {
  height: 2.6em;
}
label.trans {
  opacity: 0;
}

.content-top {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: $white;
}

.content-right {
  display: flex;
  flex-direction: column;
  background-color: $gray-200;
}

.container-scrollable {
  height: 95vh;
  overflow-y: auto;
}

.content-scrollable {
  height: 100vh;
  overflow: hidden scroll;
}

.btn-default {
  border: none;
  color: #495057;
  background-color: transparent;
}

.btn-default:hover {
  color: #00aeed;
}

.pull-right,
.float-right,
.pull-left,
.float-left {
  display: flex;
  gap: 8px;
}

.pull-right,
.float-right {
  justify-content: right;
}

.pull-left,
.float-left {
  justify-content: left;
}

.fixo {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
}

.al_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.al_left {
  display: flex;
  align-items: center;
  justify-content: left;
}
.al_right {
  display: flex;
  align-items: center;
  justify-content: right;
}

.header-body {
  svg {
    width: 1.2em;
  }
  h2 {
    font-size: 0.9em;
    margin: 0 0.5em;
  }
  .seta {
    color: $gray-500;
  }
}

table {
  margin-bottom: 0 !important;
}

.card-header {
  background-color: transparent;
  border-bottom: 1px solid #f5f7fb;
}

.migalha {
  padding: 1em;

  h6 {
    margin-bottom: 0;
  }
  svg {
    color: $gray-500;
  }
}

.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-90 {
  width: 90%;
}

.modal-content {
  background-color: #f5f7fb;
}

// menu lateral
.vertical-menu {
  height: 100%;
  .container {
    padding: 0;
  }
}

.btn-icon {
  &:hover {
    background-color: $gray-400;
  }
}

.form-control {
  &:disabled {
    background-color: #f7f7f7;
  }
}

#col-dir {
  overflow: auto;
  height: calc(100vh - 8em);
}

.scroll::-webkit-scrollbar {
  width: 15px;
}
.scroll::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 100px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #dfdfdf;
}

// ESTILO BOTÕES

.ico-mini {
  width: 1.2em;
}
.ico-gray {
  color: $gray-600;
  margin-right: 0.5em;
}

.btn-white {
  &:hover {
    background-color: $white;
    border-radius: 5px;
  }
}

.btn_opcoes {
  padding: 0 !important;
  background: transparent !important;
  border: none !important;

  svg {
    color: $blue;
    height: 1.7em;
    padding: 0.2em;
    border-radius: 3px;
  }
  &:hover {
    svg {
      color: $black;
      background-color: $white;
    }
  }
}

.btn_clean {
  svg {
    color: $black;
  }
  &:hover {
    svg {
      color: $blue;
    }
    background-color: $white;
  }
}
