
.tela{
    overflow: auto;
    height: 100vh;
}

.nav-oport{
    padding: .5em;
}
.body-oport{
    padding: 1em;
}
.header-oportunidade{
    .dados-cliente{
        svg{
            color: $white;
        }
    }
}

.funil-oport{
    margin: 0 0 1.5em 0;
    background-color: $white;
    padding: .5em;
    border-radius: 5px;
    justify-content: space-between;
    ul{
        // display: flex;
        // padding: 0;
        // overflow-y: clip !important;
        // ::-webkit-scrollbar {
        //   width: 12px;
        //   height: 12px;
        // }
        // ::-webkit-scrollbar-track {
        //   border-radius: 5px;
        // }
        // ::-webkit-scrollbar-thumb {
        //   border-radius: 5px;
        // }
        
        li{
            
            align-items: center;
            display: flex;
            svg{
                color: $gray-500;
            }

            .btn-step {
                display: block;
                border: none;
                border-radius: 50px;
                color: $gray-700;

                padding: .2em .7em;
                position: relative;
                &:hover{
                    background-color: $gray-400;
                }
               
                &.ativa{
                    color: $white;
                    background-color: $primary; 
                }
            }

            a{
                display: block;
                color: $gray-700;
                
                padding: .2em .7em;
                position: relative;
                &:hover{
                    background-color: $gray-400;
                    border-radius: 50px;
                }
               
                &.ativa{
                    color: $white;
                    background-color: $primary; 
                    border-radius: 50px;
                }
            }
        }
    }
    button{
        display: inline-block;
        white-space: nowrap;
    }
}

.barra-etapas{
    width: 100%;
    overflow: hidden;
    position: relative;

    & li{
        display: flex;
    }
}
.horizontal-scroll{
    display: flex;
}

// .horizontal-scroll {
//     cursor: e-resize;
//     position:absolute;
//     display:block;
//     top:0;
//     left:0;
//     width:30px;
//     max-height:500px;
//     margin:0;
//     background: #cab;
//     overflow-y:auto;
//     overflow-x:hidden;
//     transform:rotate(-90deg) translateY(0px) translateX(-30px);
//     transform-origin:0 0;
//     & > li {
//       display: block;
//       padding: 5px;
//       background: rgb(75, 136, 183);
//       transform: rotate(90deg);
//       transform-origin: top bottom;
//     }
//   }
  
  
.btn-drop-tags{
    position: relative;
  
    .drop-tags{
        background-color: $white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: .5em;
        width: 550%;
        position: absolute;
        top: 1.5em;
        left: -2em;
        visibility: hidden;
      a{
        padding: .5em 1em;
        display: block;
        border-bottom: 1px solid $gray-200;
      }
    }
    &:hover{
      .drop-tags{
        visibility: visible;
      }
    }
  }


.content-negocio{
  
}

.box-oport {
    img{
        margin-right: .5em;
    }
    p{
        margin-bottom: 0;
    }
    label{
        font-size: .8em;
        color: $gray-600;
        margin-bottom: 0;
        line-height: 1em;
    }
}

.coluna-oport{
    padding: .5em;
    .responsavel{
        margin: 0;
    }
    h5{
        font-size: 1.2em;
        padding-top: .5em;
    }
    .campo-personalizado{
        li{
            padding-top: .5em;
            margin-top: .5em;
            border-top: 1px dashed $gray-400;
            &:first-child{
                border: none;
            }
        }
    }
}

.produtos{
    li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px dashed $gray-400;
            &:first-child{
                border: none;
            }
            p{
                margin: .5em 0;
                &:first-child{
                    max-width: 70%;
                }
            }
    }
}

.card-canais{
    p{
        margin:0 0 0 .5em;
    }
    label{
        margin-right: 1em;
        padding: .5em 1em;
        &:last-child{
            margin-right: 0;
        }
        &:hover{
            cursor: pointer;
            background-color: $white;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
    }
}

.enviar-msg{
    button{
        margin-right: 1em;
        svg{
            width: 20px;
            height: 20px;
        }
        &.active{
            background-color: $blue;
        }
    }
}
.icon-check{
    height: 5em;
    width: 5em;
    color: $success;
}
.icon-perdido, .icon-excluir{
    height: 4.5em;
    width: 4.5em;
    border:5px solid $danger;
    border-radius: 5em;
    padding: .5em;
    margin-bottom: 1em;
    color: $danger;
}

.showTab{
    display: block;
}
.hideTab{
    display: none;
}

.opcoes-oport{
        label{
            &:hover{
            background-color: $gray-100;
        }
    }
}

.lista-oport{
    overflow: auto;
    height: calc(100vh - 26.2em);
}

.icon-btn {
  height: 39px;
}

.historico-oport{
    padding: .5em;
}
    .item-oport{
        display: flex;
        justify-content: space-between;
        padding: .5em;
        border-bottom: 1px solid $gray-200;
        
        &:last-child{
            border-bottom: 0;
        }
        &:first-child{
            margin-bottom: .5em;
            margin-top: .5em;
        }
        &:hover{
            background: #fafcff;
            border-radius: 5px;
        }
         ul{
            
            li{
                align-items: center;
                
                p{
                    margin-bottom: 0;
                }
            }
        }
        
    }