.card-lead {
    .badge {
        margin: .5em 0 .7em 0;
    }
}

.label {
    font-size: .8em;
    letter-spacing: .04em;
    color: $gray-500;
}

.protocolo {
    font-size: .7em;
    color: $gray-500;
    display: flex;

    p {
        margin-bottom: 0;

        &:last-child {
            margin-left: 1.2em;
        }
    }
}

.btn-finalizar {
    &:hover {
        background-color: $success;
        border: 1px solid $success;
    }
}

.react-tabs__tab {
    svg {
        width: 1.1em;
    }
}

.default_icons {
    border: 1px solid lightgray;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: inline-block;

    svg {
        width: 70%;
    }
}

.atalho-contato {
    li>button {
        padding: 0;
        margin-right: .5em;
        width: 2.3em;
        height: 2.3em;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #f5f7fb;

        svg {
            width: 1.3em;
            padding: 0;
        }

        &:hover {
            border: 2px solid $gray-500;
            color: #000000;
        }
    }

    .whatsapp {
        margin-right: .5em;
        width: 2.1em;
        height: 2.1em;
        padding: 0;
        background: $whatsapp;
        border: none;

        svg {
            width: 1.5em;
            padding: 0;
        }

        &:hover {
            border: 2px solid $gray-500;
            color: #000000;
        }
    }
}

.contatos-empresa {
    height: 20rem;
    overflow-y: auto;
}

.situacao {
    display: flex;
    align-items: center;
    color: $white;
    font-size: .8em;
    height: 2.2em;
    padding: 0.1em .6em;
    border-radius: 5px;

    svg {
        margin-right: .5em;
        width: 1.2em;
    }
}

.perdido {
    background-color: $danger;
}

.conquistado {
    background-color: $success;
}

.aberto {
    background-color: $primary;
}

#historico {
    height: calc(100vh - 16.5em);
}

.btn-ligacao {
    div {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 1em;
    }

    button {
        border-radius: 50%;
        width: 4em;
        height: 4em;

    }
}

// OPORTUNIDADES
.marca {
    background-color: white;
    border: 1px solid $bg-cinza;
    border-radius: 50%;
}

.header-cliente {
    padding: .5em 0;
    align-items: center;

    h5 {
        font-size: 1.2em;
        color: $gray-600;
        margin-bottom: 0;
    }

    .dados-cliente {
        border-left: 1px solid $bg-cinza;
    }

    .dados-cliente {
        ul {
            margin-left: 1em;

            li {
                margin: .5em 0;
            }

            svg {
                width: 1.2em;
                color: $primary;
            }
        }
    }
}

.react-tabs {
    .react-tabs__tab-list {
        border-bottom: 1px solid $bg-cinza;

        li {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
        }
    }
}

.react-tabs__tab-list {
    margin: 0 !important;

    .react-tabs__tab--selected {
        border-radius: 0;
        border: none !important;
        color: $blue;
        border-bottom: 4px solid $blue !important;
    }
}

.react-tabs__tab-panel {
    padding: 0;
}


// ATENDIMENTOS
.historico-atendimento {
    padding: .5em;
}

.filtro-historico {
    border-radius: 5px;
    background-color: #f5f7fb;
    padding: 1em;

    >div {
        margin-right: 1em;
    }
}

.header-atendimento {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5em 0;

    p {
        font-size: .8em;
    }
}

.lista-atendimento {
    overflow: auto;
    height: calc(100vh - 26.2em);
}

.listagem {

    &::after {
        content: "";
        position: absolute;
        top: 4.3em;
        bottom: 0;
        width: 2px;
        background: #9c9c9c;
        left: 18px;
        z-index: 0;
    }
}

.listagem {
    .item-atendimento {
        position: relative;

        .msg-atend {
            border-bottom: 1px solid $gray-300;
            border-top: 1px dashed #f2f2f2;
            margin: 0 0 0 2.5em;
            padding-top: 1em;
        }

        &:last-child {
            .msg-atend {
                border-bottom: 0;
            }
        }

        &:hover {
            background: #fafcff;
        }

        li {
            display: flex;
            align-items: center;
            padding: 0;

            &:first-child {
                z-index: 10;
                margin-right: 1em;
            }
        }

        .responsavel {
            margin: 0 1em;
        }
    }
}

// TAREFAS

//ANEXOS
.item-anexo {
    border-bottom: 1px solid $gray-400;

    &:last-child {
        border-bottom: 0;
    }

    .header-anexos {

        ul {
            padding: .5em;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                background: #fafcff;
            }
        }

        li {
            display: flex;
            flex-direction: column;

            p {
                margin-bottom: 0;
            }

            &:first-child {
                width: 20em;
                overflow: hidden;

                a {
                    white-space: nowrap;
                    width: 100%;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.todo {
    display: flex;

    .arq-anexo {
        z-index: 10;
        display: inline-block;
        height: 1.7em;
        margin-top: .4em;

        ul {
            margin-left: 2em;
        }
    }
}

// COLUNA DIREITA
.outros-dados {
    padding: .5em;
}

.contato-pessoa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: .5em 0;
}

.contatos {
    .contato-pessoa {
        border-bottom: 1px solid $gray-300;

        &:last-child {
            border-bottom: none;
        }
    }
}