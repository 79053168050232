//
// chat-leftsidebar.scss
//


.btn_mais{
  padding: 0 .5em!important;
  background: transparent!important;
  border: none!important;
svg{
  color: $black;
}
&:hover{
  svg{
    color: $blue;
  }
  background-color: $white;
}
}

.chat-leftsidebar {
  overflow: auto;
  background-color: $sidebar-sub-bg;
  border-right:1px solid #f0eff5 !important;
  transition: all .5s ease-in-out;

  @media (min-width: 992px) {
    min-width: 380px;
    max-width: 380px;
  }

  .user-status-box {
    background-color: $light;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    margin-top: 16px;
    display: block;

    .chat-user-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  
}

.hab-tags{
  cursor: pointer;
  display: flex;
  justify-content:space-between;
  label{
    cursor: pointer;
  }

    :hover{
      color:$blueHover;
    }
  
}

.css-1pahdxg-control, .css-1pahdxg-control:hover, .css-1pahdxg-control:focus {
    box-shadow: none!important;
    border: 1px solid #e6ebf5!important;
}
.css-yk16xz-control{
  border: 1px solid #e6ebf5!important;
}

.header-wrapper{
  // position: sticky;
  top: 0;
  z-index: 500;
  background-color: #fafafa;

  .btn-link{
    padding: .7em 1em;
    position: relative;
    border-radius: 5px 5px 0 0;
  }
  .btn-link.active{
    background-color: #fafafa;
  }
}

.box-filtros{
  max-height: 30em;
  overflow: auto;
  padding: 0 1em;
}

.chat-search-box {
  display: flex;

  .filtro_leads {
    border-radius: 0 5px 5px 0;
    margin: 0;

    button {
      padding: 0;
    }
  }
  .form-control {
    border-left: 0;
  }

  .input-group-text {
    background: white;
  }

  .search-icon-btn {
    font-size: 16px;
    position: absolute;
    right: 13px;
    top: 0;
  }
}

#filtrar{
  svg{
    transform: rotate(90deg);
  }
}

.chat-message-list {
  height: calc(100vh - 87px);
  overflow-x: hidden!important;

  &:hover{
    cursor: grab;
  }

  @media (max-width: 991.98px) {
    height: calc(100vh - 87px);
  }
}

.chat-message-list::-webkit-scrollbar {
  width: 15px;
}
.chat-message-list::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 100px;
}
.chat-message-list::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #dfdfdf;
}

.chat-group-list {
  height: calc(100vh - 160px);
  @media (max-width: 991.98px) {
    height: calc(100vh - 194px);
  }
}

.chat-list {
  margin: 0 .4em 0 0;

  li {

    background-color: $white;
    margin-bottom:.2em;
    border: 1px solid #f0eff5;
    align-items: center;

      .dropdown {
        padding: 0;
        &.active {
          background-color: $light;
        }
      }
      
      &.active {
        background-color: $light;
      }

    &:hover {
      background-color: $light;
    }

    &.disabled {
      pointer-events:none; 
      opacity:0.8;         
    }
    

    a {
      position: relative;
      display: block;
      padding: 15px;
      color: $gray-600;
      transition: all 0.4s;
    }

    .chat-user-message {
      font-size: 14px;
    }

    &.typing {
      .chat-user-message {
        color: $primary;
        font-weight: $font-weight-medium;

        .dot {
          background-color: $primary;
        }
      }
    }

    #oportAtiv{
      padding: 0 !important;;
    }
    .online{
      padding-right: 1em;
      position: relative;

      .origem{position: absolute; top: 1em; right: .5em;}
    }
    .unread-message {
      position: absolute;
      display: inline-block;
      right: 24px /*rtl:auto*/;
      left: auto /*rtl:0*/;
      top: 33px;

      .badge {
        line-height: 16px;
        font-weight: $font-weight-semibold;
        font-size: 10px;
      }
    }
  }
}

.info-msg{
  display: flex;
  flex-direction: column;
  border-left: 1px dashed #f2f2f2;
  width: 18%;
  min-height: 4em;
  text-align: center;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}

.btn-filtro{
  background-color: #f2f2f2;
  padding-top: 1em;
}

.chat-user-img {
  position: relative;

  .origem{position: absolute; top: 1em; right: -.5em; padding: 0!important;}

  .user-status {
    width: 10px;
    height: 10px;
    background-color: $gray-500;
    border-radius: 50%;
    border: 2px solid $card-bg;
    position: absolute;
    right: 0 /*rtl:auto*/;
    left: auto /*rtl:0*/;

    bottom: 0;
  }

  &.online {
    .user-status {
      background-color: $success;
    }
  }

  &.away {
    .user-status {
      background-color: $warning;
    }
  }
}

// contact list

.contact-list {
  li {
    cursor: pointer;
    padding: 10px 20px;
  }
}

// setting

.profile-user {
  position: relative;
  display: inline-block;

  .profile-photo-edit {
    position: absolute;
    right: 0 /*rtl:auto*/;
    left: auto /*rtl:0*/;
    bottom: 0;
  }
}

.desconectado {
  .notif_desconectado {
    display: flex;
    align-items: center;

    svg {
      width: 6em;
      margin-right: 1em;
    }
    p {
      margin: 0;
      font-size: 0.9em;
    }
  }
}

.check_origem .form-check {
  display: flex;
  position: relative;
  padding-left: 1em;
  label {
    position: relative;
    margin: 0.5em 1em 1em 0;
  }
  img {
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 5;
    cursor: pointer;
  }
  input {
    width: 2em;
    height: 2em;
    margin: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
  input:checked {
    width: 2em;
    height: 2em;
    border-radius: 50%;
  }
  .form-check-input,
  .form-check-input:focus {
    border: none;
    box-shadow: none;
  }
  .form-check-input:checked {
    background: white;
    border: 3px solid $primary;
  }
}

#filtrar_atendimento {
  @include border-bottom;
  margin-bottom: 1em;

  .seletores {
    position: relative;
    z-index: 100;
  }
  .form-check.form-switch {
    margin: 0;
    padding: 0.8em 0.8em 0 0.8em;

    .form-check-input {
      margin: 0;
      padding: 0;
    }
  }
}

