.lista {
    border: 1px solid $gray-400;
    padding: 0.8em;
    margin-bottom: 0.2em;
    border-radius: 3px;
    background-color: #fff;
}

.opcoes {

    display: flex;

    label {
        padding: .3em 1.3em;
        background-color: $white;
        cursor: pointer;
        border-radius: 50px;
        border: 1px solid $white;
        display: flex;
        align-items: center;

        &:last-child {
            margin-left: 1em;
        }

        &:hover {
            border: 1px solid $gray-400;
        }
    }
}

.ml-auto {
    margin-left: auto;
}

#btn-remove-comand {
    height: 4rem;
}

.actions-dialogFlow {
    border-radius: 20px;
    border: 1px solid #0094C9;
    color: #0094C9;
    width: 7rem;
    margin-left: auto !important;
    background-color: transparent;
    transition: 0.5s background-color, 0.5s color;
}

.actions-dialogFlow:hover {
    color: white;
    background-color: #0094C9;
}

.input-aligment {
    margin-left: auto;
    margin-right: 2.3em;
    margin-bottom: 1em;
}

.label-aligment {
    margin-right: 2.5em;
}

.input-aligment-text[type=text] {
    width: 85%;
    margin-right: 0 !important;
}

.file-upload {
    margin-left: 1.6em;
}

.list-dialogFlow {
    border-bottom: 1px solid #e6ebf5;
}

.submenu {
    .actions-dialogFlow {
        margin-left: auto !important;
    }

    div {
        margin-left: 1.5em !important;
    }
}

.status-ativo {
    color: $green;
}

.status-inativo {
    color: $gray-400;
}

.msg-prontas {
    tr {
        td {
            &:last-child {

                width: 3em;
            }
        }
    }
}

.tipo,
.status,
.id {
    width: 5em;
}

#contatos-lembrete {
    width: 100%;

    .ReactTags__tagInputField {
        width: 100%;
    }

    .ReactTags__remove,
    button {
        border: none;
        background-color: transparent;
        color: gray;
        display: inline-block;
        margin-left: 0.3rem;
    }
}

.infos-canais {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.edit-desc {
    flex-direction: inherit;
    margin: .2em 0 0 0;

    button {
        margin: 0;
        padding: 0;
    }

}

// WIDGET

.cor {
    display: grid;
    column-gap: 1em;
    grid-template-columns: 1fr 1fr 1fr;

    input {
        height: 2.5em;
        cursor: pointer;

        #color02 {
            margin: 0 1em;
        }
    }
}


#inicio-bot {
    .node-header {
        border-top: 5px solid $primary;
        border-radius: 5px 5px 0 0;

        div {
            svg {
                color: $primary;
            }
        }
    }
}

#msg-bot {
    .node-header {
        border-top: 5px solid $indigo;
        border-radius: 5px 5px 0 0;

        div {
            svg {
                color: $indigo;
            }
        }
    }
}

#trans-equipe {
    .node-header {
        border-top: 5px solid $orange;
        border-radius: 5px 5px 0 0;

        div {
            svg {
                color: $orange;
            }
        }
    }
}

#opcoes-user {
    .node-header {
        border-top: 5px solid $pink;
        border-radius: 5px 5px 0 0;

        div {
            svg {
                color: $pink;
            }
        }
    }
}

#finish {
    .node-header {
        border-top: 5px solid $success;
        border-radius: 5px 5px 0 0;

        div {
            svg {
                color: $success;
            }
        }
    }
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e6ebf5;
    position: fixed;
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}