/*
Template Name: Chatvia - Responsive Bootstrap 5 Chat App
Author: Themesbrand
Version: 2.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/


@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/scss/mixins/_utilities.scss"; 


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";


// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/helper";
@import "custom/components/accordion";
@import "custom/components/forms";
@import "custom/components/color";
@import "custom/components/node";

// estilo meets
@import "default";

@import "utilities";


// Plugins
@import "custom/plugins/custom-scrollbar";

// structure
@import "custom/structure/sidemenu";

// Pages
@import "custom/pages/chat-leftsidebar";
@import "custom/pages/user-chat";
@import "custom/pages/user-head";
@import "custom/pages/clientes";
@import "custom/pages/oportunidades";
@import "custom/pages/lead";
@import "custom/pages/novo-lead";
@import "custom/pages/configuracao";
@import "custom/pages/reports";
@import "custom/pages/user-profile-details";
@import "custom/pages/tasks";
@import "custom/pages/authentication";
@import "custom/pages/central-atendimento";
@import "custom/pages/minha-conta";
@import "custom/pages/phone-number";

