.wrapper-class {
  padding: 0.2rem;
  /* height: 50vh; */
}
.editor-class {
  background-color: white;
  padding: 1rem;
  height: 160px;
  max-height: 240px;
  min-height: 240px;
}
.toolbar-class {
  border: 1px solid white;
}

.shadow-card{
  border-radius: 4px !important;
  box-shadow: 2px 2px 3px 1px rgb(0 0 0 / 14%) !important;
  min-height: 112px;
}
