//
//  User profile details.scss
//

// User profile details

html,
body {
  height: 100vh;
  overflow: hidden;
}

.user-profile-sidebar {
  height: 100vh;
  background-color: $sidebar-sub-bg;
  display: none;
  min-width: 380px;
  max-width: 380px;
  transition: transform 1s ease-in-out;

  .nav-btn {
    transform: rotate(90deg);
  }

  .simplebar-content > div {
    width: 100%;
  }

  @media (min-width: 992px) {
    border-left: 1px solid $border-color;
  }

  @media (max-width: 1199.98px) {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
  }

  @media (max-width: 575.98px) {
    min-width: 100%;
  }
}

.user-profile-desc {
  // height: calc(100vh - 140px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 150px);
  }
}

.dados-oport {
  height: calc(100vh - 10em);
}

.avatar-sidebar {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 6em;

  .origem {
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 3;
    width: 2em;
  }

  .profile-pic {
    background-color: #00AEED;
    border-radius: 50%;
    position: absolute;
    right: 60px;
    bottom: 4px;
    z-index: 3;
    width: 1.5em;
    height: 1.5em;}
}

.teste{
  background-color: #00AEED;
}

.add_oportunidade .form-group {
  display: flex;
  align-items: center;

  i {
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    display: flex;
  }
}
.tabela {
  border: 1px solid #f4f5f7;
  background-color: #ffffff;

  thead {
    background: #f4f5f7;

    th {
      border-bottom: none;
      font-size: 13px;
    }
  }

  td:last-child {
    flex-direction: flex-end;
    width: 2em;
  }

  button {
    padding: 0;
  }
}

.d-pessoais-oport {
  @include b-bottom;

  .user-details {
    margin-top: 1em;
    padding: 0.4rem;
    word-break: break-all;
  }

  textarea {
    resize: vertical;
    width: 95%;
    background-color: #f7f7f7;
    display: block;
    width: 100%;
    height: 5rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e6ebf5;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.simplebar-content {
  form {
    div.d-flex > div {
      width: 100%;
    }
  }

  div.d-flex > svg {
    margin-right: 0.5em;
  }
}
