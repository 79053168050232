@mixin align-between {
    display: flex;
    align-items: space-between;
  }
  @mixin b-bottom {
    border-bottom: 1px solid #e5e5e5;
  }


  @mixin bg-green{
    background-color: $green;
  }
