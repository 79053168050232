// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
  }
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

table{
  tr{
    border-bottom: 1px solid #f2f2f2;
    td{
      border: none;
    }
  }
  
}